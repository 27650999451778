<template>
  <section>
    <top-alternative-section
      :image="topAlternative.image"
      :title="$t('acquirer.topAlternative.title')"
      :content="$t('acquirer.topAlternative.content')"
    ></top-alternative-section>

    <features-section
      :title="$t('acquirer.featuresSection.title')"
      :description="$t('acquirer.featuresSection.description')"
      :items="$t('acquirer.featuresSection.items')"
    ></features-section>

    <offers-section
      :items="$t('acquirer.offersSection.items')"
    ></offers-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
//import DescriptionSection from "../sections/DescriptionSection.vue";
import OffersSection from "../sections/OffersSection.vue";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";
import AdquirenteImage from "../../assets/adquirente.jpg"

export default {
  components: {
    TopAlternativeSection,
   // DescriptionSection,
    OffersSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      AdquirenteImage,
      topAlternative: {
        image: AdquirenteImage,
        title: "Adquirente",
        content:
          "Aumente el volumen de transacciones con múltiples Bancos y en distintos Agregadores"
      },
      description: {
        title: "La solucion",
        content:
          "Realizar transacciones con múltiples servicios de pago es el nuevo estándar. A través de nuestro HUB de Gestión de Medios de Pago y su ecosistema de orquestación de pagos más completo, integramos fácilmente la operación en nuevos mercados, aumentando la flexibilidad de configuración con múltiples agregadores, distintos bancos y bajo cualquier esquema de switch transaccional."
      },
      features: {
        title: "Beneficios",
        description: "Description",
        items: [
          {
            title: "Eficiencia Operativa",
            icon: "mdi-clock-check-outline",
            text:
              "Esquemas multi-adquirente con ahorros en tiempo, costos y mejoras en la eficiencia operativa.  La solución reúne transacciones de diferentes procesadores, bancos, agregadores y afiliados con múltiples sistemas en un Hub de servicios central."
          },
          {
            title: "Aumento de Ingresos",
            icon: "mdi-finance",
            text:
              "Ofrezca servicios de facturación, acepte nuevos tipos de pago y reduzca el costo por transacción."
          },
          {
            icon: "mdi-home-automation",
            title: "Automatización de Procesos",
            text:
              "Eliminación de redundancia operativa y/ automatización de procesos conciliación y liquidación a comercios."
          },
          {
            title: "Gestión del Riesgo",
            icon: "mdi-bell-alert-outline",
            text:
              "Entorno efectivo para configurar múltiples reglas que permiten definir umbrales de alertamiento por Cliente y Afiliado."
          }
        ]
      },
      offers: {
        items: [
        {
          icon: 'mdi-database-cog-outline',
          title: 'Base de Datos',
          text:
            'Administración de Base de Datos de comercios y dispositivos electrónicos.'
        },
        {
          icon: 'mdi-percent',
          title: 'Comisiones',
          text: 'Gestión y cobro de comisiones por servicio.'
        },
        {
          icon: 'mdi-ticket-outline',
          title: 'Supervisión',
          text:
            'Validación y supervisión de controversias en función a niveles de servicio.'
        },
        {
          icon: 'mdi-file-chart-outline',
          title: 'Reportes',
          text:
            'Generación y envío a clientes de reportes derivados del proceso.'
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'Microservicios',
          text:
            'Capacidades de extensión de Microservicios a Clientes y Afiliados.'
        }
      ]
      }
    };
  }
};
</script>
